// Bootstrap style utils
// Bootstrap style utils

.d-flex {
	display: flex !important;
}

.justify-content-end {
	justify-content: flex-end !important;
}

.justify-content-center {
	justify-content: center !important;
}

.justify-content-between {
	justify-content: space-between !important;
}

.align-items-center {
	align-items: center !important;
}

.align-self-end {
	align-self: flex-end !important;
}

.align-self-center {
	align-self: center !important;
}

.flex-column {
	flex-direction: column !important;
}

.flex-row {
	flex-direction: row !important;
}

.overflow-visible {
	overflow: visible !important;
}

.position-relative {
	position: relative;
}

.mr-2 {
	margin-right: .5rem !important;
}

.opacity-disabled {
	opacity: .28 !important;
}

.opacity-100 {
	opacity: 1 !important;
}

.opacity-50 {
	opacity: .5 !important;
}

.color-disabled {
	color: $disabled-slider !important;
}

.mb-2 {
	margin-bottom: .5rem !important;
}

.w-100 {
	width: 100% !important;
}

.h-100 {
	height: 100% !important;
}

.fw-bold {
	font-weight: bold !important;
}

.fs-10 {
	font-size: 10px !important;
}

.fs-13 {
	font-size: 13px !important;
}

.fs-16 {
	font-size: 16px !important;
}

.fs-21 {
	font-size: 21px !important;
}

.fs-24 {
	font-size: 24px !important;
}

.fs-42 {
	font-size: 42px !important;
}

.color-alert {
	color: $alert !important;
}

.color-accent {
	color: $accent !important;
}

.color-white {
	color: #fff !important;
}

.pointer {
	cursor: pointer !important;
}

.border-0 {
	border: 0 !important;
}

.bg-transparent {
	background: transparent !important;
}

.mb-2 {
	margin-bottom: .5rem !important;
}

.mr-2 {
	margin-right: .5rem !important;
}

.ml-2 {
	margin-left: .5rem !important;
}

.w-100 {
	width: 100% !important;
}

.h-100 {
	height: 100% !important;
}

.fw-bold {
	font-weight: bold !important;
}

.uppercase {
	text-transform: uppercase !important;
}

.text-center {
	text-align: center !important;
}

.text-right {
	text-align: right !important;
}

.text-left {
	text-align: left !important;
}

.bg-white {
	background: white !important;
}

.float-left {
	float: left !important;
}

.float-right {
	float: right !important;
}

.visible {
	visibility: visible !important;
}
.invisible {
	visibility: hidden !important;
}

.clickable, .clickable input {
	cursor: pointer !important;
}

.p-0 {
	padding: 0 !important;
}

.pl-0 {
	padding-left: 0 !important;
}

.red-dot {
	width: 15px;
	height: 15px;
	border-radius: 15px;
	background-color: red;
	opacity: 0.6;
}

.sensor-icon {
	background: rgba(59, 177, 205, .9);
}

.raw-point {
	background: transparent;

	div {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		opacity: 0.5;
	}
}

.point-cloud-2d-target-info-data {
	background: rgba(86, 86, 86, 1);
	width: fit-content;
	white-space: nowrap;
	padding: 10px;
	border-radius: 4px;
	color: white;
	box-shadow: 0 0 4px 0 rgba(204, 204, 204, 1);

	&::before {
		display: none;
	}
}

.text-transform-none {
	text-transform: none !important;
}

.z-index-990 {
	z-index: 990 !important;
}
