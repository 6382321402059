.layer-tools {
	position: absolute;
	left: 70px;
	height: 37px;
	display: flex;
	align-items: center;
	box-shadow: 2.1px 2.1px 7.9px 1.1px rgba(0, 0, 0, 0.48);
	background-color: $default;
	z-index: 1;
	width: auto;
	button {
		width: 40px;
		height: 40px;
		box-shadow: none;
		background-size: 18px;
		background-repeat: no-repeat;
		background-position: center;
		background-color: transparent;
	}
}
