@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~nouislider/distribute/nouislider.min.css";
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import "~c3/c3.css";
@import "~leaflet/dist/leaflet.css";
@import "~leaflet-draw/dist/leaflet.draw.css";

@import "./styles/vars";
@import "./styles/utils";
@import "./styles/mixins";
@import "./styles/fonts";
@import "./styles/common";
@import "./styles/base/buttons";
@import "./styles/components/buttons";
@import "./styles/components/inputs";
@import "./styles/components/layers";
@import "./styles/components/slides";
@import "./styles/components/popups";
@import "./styles/components/toolbar";
