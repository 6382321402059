@import "../vars";

%g-btn {
	min-width: 80px;
	height: 25px;
	background: transparent;
	font-family: "Roboto", sans-serif;
	font-size: 12.1px;
	font-weight: 300;
	text-align: center;
	line-height: 25px;
	color: #fff !important;
	cursor: pointer;
	border: 1px solid $light;
	border-radius: 12.1px;
}

%click-buttons {
	box-shadow: none;
	line-height: unset;
	font-weight: 100;
}
