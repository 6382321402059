.toolbar {
	.container {
		display: flex;
		align-items: center;
	}

	.button-container {
		// Margin between groups
		text-align: center;
		font-size: $font-size-small;
		color: #d12059;
		display: flex;
		justify-content: center;
		font-size: $font-size-small;
		margin-right: 10px;

		&-left {
			button {
				border: 0;
			}
		}

		&-right {
			justify-content: flex-end;
		}
	}

	.button-container:last-child {
		margin-right: 0;
	}

	.button-container + .button-container + .button-container + .button-container + .button-container button {
		white-space: pre;
	}

	.mat-button, .mat-fab, .mat-flat-button, .mat-icon-button, .mat-mini-fab, .mat-raised-button, .mat-stroked-button {
		font-family: "Roboto Light";
	}

	.button-container button:last-child {
		margin-right: 0;
	}
}

.toolbar-panel {
	margin: 0 10px;
	border-radius: 5px;
	display: inline-block;
	position: relative;
	&:first-child {
		margin-left: 0;
	}
	&:last-child {
		margin-right: 0;
	}
	mat-form-field {
		height: 52px;
	}
	&__title {
		position: absolute;
		top: -2px;
		left: 7px;
		color: #3BB1CD;
		z-index: 1;
		@media (min-width: 1600px) {
			left: 15px;
		}
	}
}
