@import '../vars';

%dropdown-panel {
	box-shadow: 2.8px 2.8px 10.6px 1.4px rgba(0, 0, 0, 0.48);
	background: $default;
	font-family: "Roboto Light", sans-serif;
	color: $light;
	border-radius: 2.8px;

	.mat-select-content {
		background: $default;
	}

	.mat-option, .mat-option.mat-active, .mat-option-text, .mat-option.mat-selected {
		color: $light;
	}
}

input[type="checkbox"], input[type="radio"] {
	appearance: none;
	width: 9px;
	height: 9px;
	background-image: url(../../assets/images/svg/toolbar/rounded-rectangle-1978.svg);
	background-size: 9px 9px;
	background-position: center center;
	background-repeat: no-repeat;

	&:checked {
		background-image: url(../../assets/images/svg/rounded-rectangle-1978.svg), url(../../assets/images/svg/toolbar/Untitled-1.png);
		background-size: 9px 9px;
	}

	&:disabled {
		background-image: url(../../assets/images/svg/toolbar/rounded-rectangle-1978-disabled.svg);
	}
}

input[type="search"], input[type="text"], input[type="number"] {
	color: $light;
}
input[type="search"]::-webkit-input-placeholder, input[type="text"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder {
	color: $light;
}
input[type="search"]::-moz-placeholder, input[type="text"]::-moz-placeholder, input[type="number"]::-moz-placeholder{
	color: $light;
}
input[type="search"]:focus::-webkit-input-placeholder, input[type="text"]:focus::-webkit-input-placeholder, input[type="number"]:focus::-webkit-input-placeholder {
	color: transparent;
}
input[type="search"]:focus::-moz-placeholder, input[type="text"]:focus::-moz-placeholder, input[type="number"]:focus::-moz-placeholder{
	color: transparent;
}

input.dark-placeholder::-webkit-input-placeholder, input.dark-placeholder::-moz-placeholder {
	color: $sort-icon;
}
input.dark-placeholder::-webkit-input-placeholder:focus, input.dark-placeholder::-moz-placeholder:focus {
	color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="file"] {
	opacity: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	display: none;
}

mat-radio-button {
	&.mat-radio-button {
		display: block;

		.mat-radio-outer-circle {
			border-color: transparent;
			border-radius: unset;
			background-image: url(../../assets/images/svg/settings/radioelips.svg);
			background-repeat: no-repeat;
			background-size: 12px 12px;
			border-width: unset;
		}

		.mat-radio-container {
			margin-top: 1px;
		}

		.mat-radio-label {
			margin-left: -3px;
			line-height: 27px;

			&-content {
				font-size: 12.1px;
			}
		}

		.mat-radio-ripple {
			display: none;
		}

		&.mat-accent {
			&.mat-radio-checked .mat-radio-outer-circle {
				border-color: transparent;
				background-image: url(../../assets/images/svg/settings/Radio_Button-Selected.svg), url(../../assets/images/svg/settings/Radio_Button-Selected.svg);
				border-radius: unset;
				background-repeat: no-repeat;
				background-size: 12px 12px;
			}

			.mat-radio-inner-circle {
				background-color: transparent;
			}
		}
	}
}

.mat-button-toggle-group {
	border: 1px solid $light;
	border-radius: 12.1px;
}

.mat-button-toggle-appearance-standard button .mat-button-toggle-label-content {
	line-height: 26px;
}

.mat-button-toggle.mat-button-toggle-checked {
	background-color: $accent;

	.mat-button-toggle-label-content {
		color: black;
	}
}

.mat-button-toggle, .mat-button-toggle-disabled, .mat-button-toggle-disabled.mat-button-toggle-appearance-standard { //, .mat-button-toggle-disabled.mat-button-toggle-appearance-standard
	min-width: 80px;
	height: 25px;
	background: transparent;
	font-family: "Roboto";
	font-size: 12.1px;
	font-weight: 300;
	text-align: center;
	line-height: 25px;
	color: #fff;
	cursor: pointer;
}

mat-form-field.mat-form-field:not(.mat-form-field-invalid) {
	&.mat-focused .mat-form-field-ripple, .mat-form-field-underline {
		background-color: white;
		background-image: none;
	}

	&.mat-primary .mat-select-arrow,
	.mat-select-value-text, .mat-input-element, .mat-datepicker-toggle-default-icon, mat-pseudo-checkbox.mat-pseudo-checkbox.mat-option-pseudo-checkbox {
		color: white;
	}
}

.mat-pseudo-checkbox {
	color: white;
}

mat-checkbox {
	color: $accent;

	.mat-checkbox-label {
		font-size: 14px;
	}

	.mat-checkbox-frame {
		border-color: $accent;
	}

	&.mat-checkbox-checked {
		&.mat-accent .mat-checkbox-background {
			background-color: $accent;
		}

		&.mat-accent .mat-ripple .mat-ripple-element {
			background-color: $accent;
		}

		&.mat-checkbox-focused {
			.mat-ink-ripple {
				background-color: $accent;
			}
		}
	}
}

.mat-checkbox-disabled .mat-checkbox-label {
	color: $gray;
}

// Mat Slider Toggle Start \\

.mat-slide-toggle-bar {
	background: linear-gradient(to bottom, #6EC3D6 0%, #39ACC8 100%);
}

app-slide-toggle {
	.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
		background: #8996B5;
	}

	.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
		background-color: #fafafa;
	}

	.mat-slide-toggle {
		height: 16px;
	}

	&.app-slide-toggle .mat-slide-toggle-bar {
		background: #8996B5;
	}

	&.app-slide-toggle .mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
		background: linear-gradient(to bottom, #79c7da 0%, #3bb1cd 100%);
	}
}

.mat-slide-toggle-label {
	.mat-slide-toggle-content {
		font-size: 10.7px;
	}

	.mat-slide-toggle-bar {
		margin-left: 8px;
	}

	.mat-slide-toggle-content {
		font-size: 10.7px;
		font-family: "Roboto Light";
	}
}

.mat-slider-horizontal .mat-slider-wrapper {
	background: #8895b5;
	border-radius: 2px;
	height: 5px;
	background: linear-gradient(to bottom, #79c7da 0%, #3bb1cd 100%);
}

.mat-accent .mat-slider-thumb, .mat-accent .mat-slider-thumb-label, .mat-accent .mat-slider-track-fill {
	background: #fff;
	background-color: #fff !important;
}

// Mat Slider Toggle End \\

.noUi-target {
	height: 4px;
	border-radius: 16px;
	border: 0;
	background: #8895b5;
	box-shadow: none;
}

.noUi-horizontal .noUi-handle {
	width: 9px;
	height: 9px;
	border-radius: 50%;
	box-shadow: none;

	&::before {
		display: none;
	}

	&::after {
		display: none;
	}
}

.noUi-handle-lower {
	left: -5px;
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
	right: -5px;
	top: -3px;
	cursor: pointer;
}

.noUi-connect {
	background-color: #79c7da;
}

mat-label {
	color: $light;
}

.mat-primary.mat-select-panel {
	@extend %dropdown-panel;
}

.mat-autocomplete-panel {
	@extend %dropdown-panel;

	&.mat-autocomplete-visible {
		min-width: calc(100% + 64px);
	}

	.mat-option {
		font-size: 14px;
	}

	.mat-option.mat-selected:not(.mat-active):not(:hover), .mat-option.mat-selected:not(.mat-active):not(:hover) {
		background: $default;
	}

	.mat-option.mat-selected:not(.mat-active):not(:hover), .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
		color: $light;
	}
}

.mat-autocomplete-trigger {
	caret-color: $light;
	text-overflow: ellipsis;
}

.mat-form-field-disabled {
	opacity: $opacity-disabled;
}


.mat-datepicker-content {
	.ngx-mat-timepicker {
		mat-form-field.mat-form-field:not(.mat-form-field-invalid) .mat-form-field-underline {
			background-color: rgba(0, 0, 0, 0.26);
		}
		.mat-form-field input {color: #212121;}
	}
}


.mat-option {
	&:hover:not(.mat-option-disabled),
	&:focus:not(.mat-option-disabled) {
		background: $button-hover-color;
	}
	.mat-option-ripple .mat-ripple-element {
		background-color: $button-ripple-color;
	}
}
