.modal-default {
	z-index: 101;

	.mat-dialog-container {
		border-radius: $borderRadius;
		padding: 0;
		z-index: 100;
		position: relative;
		overflow: hidden;
		box-shadow: 2.1px 2.1px 7.9px 1.1px rgba(0, 0, 0, 0.48);
	}

	h3 {
		background: #323B59;
		padding: 23px 0;
		text-align: center;
		color: #fff;
		font-family: "Roboto";
		font-size: 19.8px;
	}

	div.message {
		min-height: 100px;
		padding: 10px 15px;
		position: relative;
		background: $default;
		display: flex;
		justify-content: center;
		flex-direction: column;

		p {
			width: 100%;
			min-height: 10px;
			font-size: 19.8px;
			color: #667290;
			text-align: center;
			border-radius: 0 0 $borderRadius $borderRadius;
			word-break: break-word;
			padding-bottom: 20px;
		}
	}

	.default-panel {
		height: 56px;
		border-radius: 0;
		// box-shadow: 2.1px 2.1px 7.9px 1.1px rgba(0, 0, 0, 0.48);
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		padding: 15px 30px;

		.button-right {
			margin-left: auto;
		}

		.g-btn {
			@extend %g-btn;
		}

		button {
			margin: 0;
		}
	}
}

.welcome-background {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

	.first-img, .second-img {
		position: absolute;
	}

	.first-img {
		right: 52px;
		top: 277px;
		width: 194px;
		height: 233px;
	}

	.second-img {
		top: 193px;
		left: 38px;
		width: 277px;
		height: 287px;
	}
}

.recorded-data-title {
	display: flex;

	span {
		font-size: 80%;
		padding-left: 5px;
		padding-top: 15px;
		color: #959ebd;
	}
}

.settings-popup {
	.mat-dialog-container {
		@media (max-height: 630px) {
			padding: 0;
		}
	}
}

.user-input {
	.mat-dialog-container {
		height: unset;
	}
}
