@import '../vars';

button:disabled, mat-button-toggle-group.disabled {
	cursor: default;
}

button:disabled {
	opacity: $opacity-disabled;
}

.g-btn.mat-raised-button {
	@extend %g-btn;
}

.g-btn {
	@extend %g-btn;
}

.slc-btn.mat-raised-button {
	background-color: #0F152B;
}

.slc-btn {
	background-color: #0F152B;
}

button.mat-raised-button {
	min-width: auto;
}

.no-spinners {
	-moz-appearance: textfield;
}

.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}


.material-button {
	.material-icons, .material-icons-outlined {
		margin-left: -30px;
		margin-right: 11px;
	}
}

.material-button span:not(.material-icons):not(.material-icons-outlined) {
	vertical-align: super;
}

.a-btn {
	border-color: $accent;
}

.mat-button-focus-overlay {
	background-color: transparent;
}

.button-outline {
	border: 1px solid #3bb1cd;
	background: transparent;
	color: #3bb1cd;
	border-radius: 13px;
	font-size: 14px;
	width: 110px;
	height: 26px;
	font-family: "Roboto Bold", sans-serif;
	cursor: pointer;
}

.button-container {
	// Margin between groups
	text-align: center;
	font-size: $font-size-small;
	color: #d12059;
	display: flex;
	justify-content: center;
	font-size: $font-size-small;
	&-left {
		button {
			border: 0;
		}
	}

	&-right {
		justify-content: flex-end;
		padding: 6px 0 0;
		height: 61px;
	}

	button, button.button-active {
		background: none;
		border-radius: 5px;
		color: $light;
		font-size: $font-size-smaller;
		line-height: $font-size-smaller;
		cursor: pointer;
		position: relative;
		z-index: 1;
		height: 50px;
		min-width: 5.2vw;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		padding: 0 5px;
		box-shadow: none;
		border: 0;
		margin-right: 10px;
	}

	button.border-accent {
		border: 1px solid $accent;
	}

	button.button-active {
		color: $accent;
	}
}

.button-container:last-child {
	margin-right: 0;
}
