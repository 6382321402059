// Colors //
$default: #262d47;
$text: #e9effb;
$accent: #3bb1cd;
$disabled-slider: #484F66;
$disabled-connect-slider: #8895b5;
$dark-default: #171c30;
$darker: #252c46;
$light: #e9effb;
$alert: #ee4f87;
$sort-icon: #6a7695;
$error: #f15050; //#fd6458;
$gray-light: #959ebd;
$gray: #727a96;
$min-container-width: 1220px;

// Margin
$margin: 13px;

// font-sizes
// top toolbar font-size
$font-size-smallest: 10px;
$font-size-smaller: 11px;
$font-size-small: 12px;
$font-size-regular: 14px;
$font-size-big: 14px;
$font-size-big: 17px;
//$big;
//$bigger;
//$biggest;

// Default border-radius
$borderRadius: 2.8px;

$shadow-default: 2.8px 2.8px 10.6px 1.4px rgba(0, 0, 0, 0.48);
$button-hover-color: rgba(100, 100, 100, 0.32);
$button-ripple-color: rgba(200, 200, 200, 0.2);
//$button-ripple-color: rgba(150, 150, 150, 0.32);

:root {
	--button-ripple-color: #{$button-ripple-color};
}

// Opacity
$opacity-disabled: 0.24;
