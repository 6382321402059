@font-face {
	font-family: "Roboto Light";
	src: url(../assets/fonts/Roboto-Light/Roboto-Light.ttf);
}

@font-face {
	font-family: "Roboto";
	src: url(../assets/fonts/Roboto-Regular/Roboto-Regular.ttf);
}

@font-face {
	font-family: "Roboto-Thin";
	src: url(../assets/fonts/Roboto-Thin/Roboto-Thin.ttf);
}

@font-face {
	font-family: "Roboto Bold";
	src: url(../assets/fonts/Roboto-Bold/Roboto-Bold.ttf);
}

@font-face {
	font-family: "Roboto Black";
	src: url(../assets/fonts/Roboto-Black/Roboto-Black.ttf);
}

@font-face {
	font-family: "Roboto Medium";
	src: url(../assets/fonts/Roboto-Medium.ttf);
}

@font-face {
	font-family: "Quenda-Medium";
	src: url(../assets/fonts/Quenda-Medium.otf);
}

@font-face {
	font-family: "Roboto Mono";
	src: url(../assets/fonts/RobotoMono-Regular.ttf);
}

/* fallback */
@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(../assets/fonts/Material-Icons.woff2) format('woff2');
}
/* fallback */
@font-face {
	font-family: 'Material Icons Outlined';
	font-style: normal;
	font-weight: 400;
	src: url(../assets/fonts/Material-Icons-Outlined.woff2) format('woff2');
}

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
}

.material-icons-outlined {
	font-family: 'Material Icons Outlined';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: 'liga';
	-webkit-font-smoothing: antialiased;
}
