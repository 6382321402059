@import './vars';

html, body {
	margin: 0;
	width: 100%;
	height: 100%;
	background: #171c30;
}

label {
	font-size: 10.2px;
}

img {
	width: 100%;
}

ul, ol, p, h1, h2, h3, h4, h5, h6 {
	margin: 0;
}

input, button {
	padding: 0;
	margin: 0;
}

ul {
	padding: 0;

	li {
		list-style-type: none;
	}
}

* {
	box-sizing: border-box;
	outline: none;
	font-family: "Roboto Light", Lato-Regular, sans-serif;
}

// Background overlays settings popup
.cdk-overlay-container .cdk-overlay-dark-backdrop {
	background: rgba(19, 24, 48, .8);
}

.default-panel {
	width: 100%;
	background-color: $default;
	border-radius: $borderRadius;
	box-shadow: 2.1px 2.1px 7.9px 1.1px rgba(0, 0, 0, 0.48);
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.mat-dialog-container {
	background: none;
	box-shadow: 0 0 0 0 #fff;
}

// Chart
.c3 svg {
	height: 100%;
	transform: none;
}

.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
	background-color: rgba(238, 238, 238, .1);
	opacity: 1;
}

div.mat-menu-panel {
	min-width: 46px;
	background-color: #252C46;
	box-shadow: 2.1px 2.1px 7.9px 1.1px rgba(0, 0, 0, 0.48);
	overflow: hidden;
}

.mat-menu-panel .mat-menu-item {
	height: 45px;
	line-height: 45px;
}

.mat-menu-panel .mat-menu-content:not(:empty) {
	padding: 0;
}

.hmb-container {
	width: 15px;
	margin: 0 auto;

	.hmb-item {
		display: block;
		margin: 0 auto;
		width: 18px;
		height: 2px;
		margin-bottom: 2px;
		background: #fff;
		border-radius: 4px;
	}
}

.material-icons,
.material-icons-outlined {
	&.md-18 {
		font-size: 18px;
	}

	&.md-22 {
		font-size: 22px;
	}

	&.md-36 {
		font-size: 36px;
	}

	&.md-48 {
		font-size: 48px;
	}
}

.hide-title-bg {
	.welcome-background, .connection-status-logo, .or, .connecting-popup__logo {
		display: none;
	}
}

.view-menu {
	margin-top: -40px;
}

.leaflet-container {
	width: 100%;
	height: 100%;
	background: transparent;
}

.leaflet-overlay-pane img.leaflet-heatmap.leaflet-image-layer {
	mix-blend-mode: multiply;
	z-index: 2 !important;
}

// Animation
@keyframes placeHolderShimmer{
	0%{
		background-position: -468px 0
	}
	100%{
		background-position: 468px 0
	}
}

.animated-background {
	animation-duration: 2s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: #F6F6F6;
	background: linear-gradient(to right, $accent 8%, #70cae0 18%, $accent 33%);
	height: 96px;
	position: relative;
}

.border-accent {
	border: 1px solid $accent;
}

.mat-progress-bar-fill::after {
	background-color: $accent;
}

.leaflet-bar{
	background-color: #ffffff;
}
.leaflet-control {
	cursor: pointer;
}
.result-tooltip{
	background-color: white;
	border-width: medium;
	border-color: #de0000;
	font-size: smaller;
	display: none;
}
.moving-tooltip{
	background-color: rgba(255, 255, 255, .7);
	background-clip: padding-box;
	opacity: 0.5;
	border: dotted;
	border-color: red;
	font-size: smaller;
	display: none;
}
.plus-length{
	padding-left: 45px;
}

.leaflet-container img.leaflet-image-layer {
	z-index: initial !important;
}

.conversion-tooltip {
	margin-top: 15px;
	margin-left: 0;
	background: #d0fff4;
	color: #262d48;
	display: flex;
	font-weight: bold;

	& > div {
		border-left: 1px solid #262d48;
	}
	&:before {
		display: none;
	}
}

.people-icon {
	font-size: inherit;
	line-height: inherit;
	vertical-align: bottom;
}

.geo-fence-name {
	background: #3388ff;
	color: white;
	border: none;
	border-radius: 0 3px 3px 0;
	padding-left: 1px !important;

	&::before {
		display: flex;
		content: '';
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 60' preserveAspectRatio='none'%3E%3Cpath fill='%233388FF' d='M80,60C34,53.5,64.417,0,0,0v60H80z'%3E%3C/path%3E%3C/svg%3E");
		width: 30%;
		height: 100%;
		transform: scale3d(-1,1,1);
		position: absolute;
		left: calc(-30% + 1px);
		top: 0;
	}
}

.leaflet-control.rotate-button {
	width: 30px;
	height: 30px;
	.degrees {
		font-size: 10px;
		position: absolute;
		top: -2px;
		right: 0;
	}
	.i-rotate {
		font-size: 18px;
		margin-top: 8px;
	}
}

.leaflet-zoom-hide {
	z-index: 101;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
	color: white;
}
